import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { TestTheme } from './utils';
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "test-your-custom-theme"
    }}>{`Test your custom theme`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <p>{`This tool is useful to get a more realistic sense about how the theme is going to look like in a real scenario, we have put some elements
together that can help see the colorations. The entire doc page will use the colorations for the theme that is being provided
so you can see it across the entire page and with the components that are rendered as examples.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We recommend you open in 1 tab this page and in a separate tab the theme creation tool so you can copy and paste from that page to this page`}</p>
    </blockquote>
    <TestTheme mdxType="TestTheme" />
    <br />
    <iframe src="https://giphy.com/embed/LS2WElet7iL31i3bxh" width="480" height="480" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      